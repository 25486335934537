import { all } from 'redux-saga/effects';

import UserSagas from './sagas/auth.sagas';
import ProductSagas from './sagas/product.sagas';
import SettingSagas from './sagas/setting.sagas';
import OrdersSagas from './sagas/orders.sagas';

export default function* rootSaga() {
  yield all([
    ...UserSagas,
    ...ProductSagas,
    ...SettingSagas,
    ...OrdersSagas
  ]);
}
