import { getOrdersList } from "@types";
import { Modal } from "react-bootstrap";

type Props = {
    isOpen?: getOrdersList | null;
    setIsOpen?: any;
};

const OrderModal = (props: Props) => {
    return(
        <div>
            <Modal
                style={{direction: 'rtl'}}
                tabIndex={-1}
                aria-hidden="true"
                size="lg"
                show={props.isOpen ? true : false }
                onHide={() => props.setIsOpen(null)}
                >
                <div className="modal-header">
                    جزئیات سفارش
                </div>
                <div className="modal-body py-lg-10 px-lg-10">
                    <div className="col-12 py-2">
                        <h3 className="mb-4 w-100 bg-secondary p-3 rounded">آدرس</h3>
                        <div className="d-flex flex-wrap">
                            <p className="col-md-6 col-12">آدرس: {props.isOpen?.address.address}</p>
                            <p className="col-md-6 col-12">شماره موبایل: {props.isOpen?.address.phone}</p>
                        </div>
                    </div>
                    <div className="col-12 py-2">
                        <h3 className="mb-4 w-100 bg-secondary p-3 rounded">سفارش</h3>
                        <div className="d-flex flex-wrap">
                            <p className="col-md-6 col-12">کد پرداخت: {props.isOpen?.order.pay_code}</p>
                        </div>
                    </div>
                    <div className="col-12 py-2">
                        <h3 className="mb-4 w-100 bg-secondary p-3 rounded">هزینه</h3>
                        <div className="d-flex flex-wrap">
                            <p className="col-md-6 col-12">تخفیف کوپن: {props.isOpen?.prices.coupon_discount}</p>
                            <p className="col-md-6 col-12">قیمت نهایی: {props.isOpen?.prices.final_price}</p>
                            <p className="col-md-6 col-12">روش پرداخت: {props.isOpen?.prices.pay_method}</p>
                            <p className="col-md-6 col-12">پرداختی: {props.isOpen?.prices.price_to_pay}</p>
                            <p className="col-md-6 col-12">قیمت حمل و نقل: {props.isOpen?.prices.shipping_price}</p>
                            <p className="col-md-6 col-12">وضعیت: {props.isOpen?.prices.status}</p>
                            <p className="col-md-6 col-12">مجموع تخفیف: {props.isOpen?.prices.total_discount}</p>
                        </div>
                    </div>
                    <div className="col-12 py-2">
                        <h3 className="mb-4 w-100 bg-secondary p-3 rounded">بقیه موارد</h3>
                        <div className="d-flex flex-wrap">
                            <p className="col-md-6 col-12">اظهار نظر: {props.isOpen?.others.comment}</p> 
                            <p className="col-md-6 col-12">روز تحویل: {props.isOpen?.others.delivery_day}</p>
                            <p className="col-md-6 col-12">توضیحات تحویل: {props.isOpen?.others.delivery_description}</p>
                            <p className="col-md-6 col-12">شماره فاکتور: {props.isOpen?.others.factor_number}</p>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
}
export default OrderModal;