import { productActions } from "../slices";
import { DataNav, IPaginateParams, ITableData, ResData, addProductReq, cateListForTable, cateListServices, category, changeMyStocks, changeMyStocksRes, exelFileRes, getStock, myStocks, productCatePaginate, storeStock } from "@types";
import { put, takeLeading } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { store } from "../store";
import axios from "axios";
import { categoryServices } from "../../services/";
import { addProductServices, categoriesServices, changeMyStockServices, getCateListServices, getMyEditStockServices, getMyStockServices, importExelFileServices, storeStockServices } from "src/app/services/product.services";


function* getProductCate({ payload }: PayloadAction<productCatePaginate<IPaginateParams>>) {
  yield;
  try {
    store.dispatch(productActions.setBtnLoading(true));
    const result: cateListForTable<ITableData<cateListServices>> = yield getCateListServices({slug: payload.slug ,paginate:{
      link: payload.paginate.link,
      pageSize: payload.paginate.pageSize
    } , search: payload.search});
    if (result.status) {
      console.log(result)
      store.dispatch(productActions.setBtnLoading(false));
      store.dispatch(productActions.setCateProducts(result));
    } else {
      console.log(result)
      toast.error("مشکلی پیش آمده است");
      store.dispatch(productActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(productActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}
function* postStoreStock({ payload }: PayloadAction<storeStock>) {
  yield;
  try {
    store.dispatch(productActions.setModalLoading(true));
    const result: ResData = yield storeStockServices(payload);
    if (result.status) {
      toast.success(result.message);
      store.dispatch(productActions.setModalLoading(false));
      store.dispatch(productActions.setModalOpen({
        isOpen: null,
        stockOrEdit: ''
      }));
      store.dispatch(productActions.getMyStocks());
    } else {
      toast.error(result.message);
      store.dispatch(productActions.setModalLoading(false));
    }
  } catch (error) {
    store.dispatch(productActions.setModalLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}
function* postAddProduct({ payload }: PayloadAction<DataNav<addProductReq>>) {
  yield;
  try {
    store.dispatch(productActions.setBtnLoading(true));
    const result: ResData = yield addProductServices(payload.data);
    if (result.status) {
      store.dispatch(productActions.setBtnLoading(false));
      payload.navigate('/product/list', { replace: true });
      toast.success(result.message);
    } else {
      toast.error(result.message);
      store.dispatch(productActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(productActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}
function* importExelFile({ payload }: PayloadAction<File>) {
  yield;
  try {
    store.dispatch(productActions.setBtnLoading(true));
    const result: exelFileRes = yield importExelFileServices(payload);
    if (result.status) {
      store.dispatch(productActions.setBtnLoading(false));
    } else {
      toast.error("مشکلی پیش آمده است");
      store.dispatch(productActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(productActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}
function* getCateList() {
  yield;
  try {
    const result: category[] = yield categoriesServices();
    if (result) {
      store.dispatch(productActions.setCategory(result));
    }
  } catch (error) {
    console.log(error);
    toast.error("مشکلی پیش آمده است");
  }
}

function* getMyStocks() {
  yield;
  try {
    const result: myStocks = yield getMyStockServices();
    if (result) {
      store.dispatch(productActions.setMyStocks(result));
      let barcodes: string[] = [];
      result.stocks.map((obj)=>{
        barcodes.push(obj.barcode);
      })
      store.dispatch(productActions.setMyStocksBarcode(barcodes));
    }
  } catch (error) {
    console.log(error);
    toast.error("مشکلی پیش آمده است");
  }
}

function* getMyEditStock({ payload }: PayloadAction<number>) {
  yield;
  try {
    store.dispatch(productActions.setModalLoading(true));
    const result: getStock = yield getMyEditStockServices(payload);
    if (result) {
      store.dispatch(productActions.setMySingleStock(result));
      store.dispatch(productActions.setModalLoading(false));
    }
  } catch (error) {
    console.log(error);
    toast.error("مشکلی پیش آمده است");
  }
}

function* changeMyStock({ payload }: PayloadAction<changeMyStocks>) {
  yield;
  try {
    store.dispatch(productActions.setModalLoading(true));
    const result: changeMyStocksRes = yield changeMyStockServices(payload);
    if (result.status) {
      toast.success(result.message);
      store.dispatch(productActions.setModalLoading(false));
      store.dispatch(productActions.setModalOpen({
        isOpen: null,
        stockOrEdit: ''
      }));
    }
  } catch (error) {
    console.log(error);
    toast.error("مشکلی پیش آمده است");
  }
}

const productSagas = [
  takeLeading(productActions.getProductCate.toString(), getProductCate),
  takeLeading(productActions.importExelFile.toString(), importExelFile),
  takeLeading(productActions.getCateList.toString(), getCateList),
  takeLeading(productActions.postStoreStock.toString(), postStoreStock),
  takeLeading(productActions.postAddProduct.toString(), postAddProduct),
  takeLeading(productActions.getMyStocks.toString(), getMyStocks),
  takeLeading(productActions.getMyEditStock.toString(), getMyEditStock),
  takeLeading(productActions.changeMyStock.toString(), changeMyStock)
];
export default productSagas;
