import { authActions } from "../slices";
import { category, LoginReqData, LoginResData, RegisterReqData, RegisterResData, SmsReqData } from "@types";
import { put, takeLeading } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { store } from "../store";
import axios from "axios";
import { categoryServices, loginServices, registerServices, smsServices } from "../../services/AuthenticateServices";

// function* salerLogin({ payload }: PayloadAction<LoginReqData>) {
//   yield;
//   try {
//     store.dispatch(authActions.setBtnLoading(true));
//     const result: LoginResData = yield loginServices(payload);
//     if (result.status) {
//       toast.success('ورود با موفقیت انجام شد');
//       store.dispatch(authActions.setUserData(result));
//       store.dispatch(authActions.setBtnLoading(false));
//     } else {
//       toast.error(result.message);
//       store.dispatch(authActions.setBtnLoading(false));
//     }
//   } catch (error) {
//     store.dispatch(authActions.setBtnLoading(false));
//     console.log(error);
//     toast.error("مشکلی پیش آمده است");
//   }
// }

function* getCategoriesForRegister() {
  yield;
  try {
    const result: category[] = yield categoryServices();
    if (result) {
      store.dispatch(authActions.setCategory(result));
    }
  } catch (error) {
    console.log(error);
    toast.error("مشکلی پیش آمده است");
  }
}

function* salerRegister({ payload }: PayloadAction<RegisterReqData>) {
  yield;
  try {
    store.dispatch(authActions.setBtnLoading(true));
    const result: RegisterResData = yield registerServices(payload);
    if (result.status && result.token) {
      store.dispatch(authActions.setSms(true));
      localStorage.setItem('token', result.token);
      store.dispatch(authActions.setBtnLoading(false));
    } else {
      toast.error("مشکلی پیش آمده است");
      store.dispatch(authActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(authActions.setBtnLoading(false));
    console.log(error);
    toast.error("مشکلی پیش آمده است");
  }
}
function* salerSms({ payload }: PayloadAction<SmsReqData>) {
  yield;
  try {
    store.dispatch(authActions.setBtnLoading(true));
    const result: RegisterResData = yield smsServices(payload);
    if (result.status) {
      toast.success('ثبت نام با موفقیت انجام شد');
      store.dispatch(authActions.setBtnLoading(false));
      store.dispatch(authActions.setUserData(localStorage.getItem('token')));
    } else {
      toast.error("مشکلی پیش آمده است");
      store.dispatch(authActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(authActions.setBtnLoading(false));
    console.log(error);
    toast.error("مشکلی پیش آمده است");
  }
}
function* salerLogin({ payload }: PayloadAction<LoginReqData>) {
  yield;
  try {
    store.dispatch(authActions.setBtnLoading(true));
    const result: LoginResData = yield loginServices(payload);
    if (result.status && result.token) {
      toast.success('ورود با موفقیت انجام شد');
      store.dispatch(authActions.setUserData(result.token));
      store.dispatch(authActions.setBtnLoading(false));
    } else {
      toast.error("مشکلی پیش آمده است");
      store.dispatch(authActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(authActions.setBtnLoading(false));
    console.log(error);
    toast.error("مشکلی پیش آمده است");
  }
}



const authSagas = [
  takeLeading(authActions.salerRegister.toString(), salerRegister),
  takeLeading(authActions.getCategoriesForRegister.toString(), getCategoriesForRegister),
  takeLeading(authActions.salerLogin.toString(), salerLogin),
  takeLeading(authActions.salerSms.toString(), salerSms)
];
export default authSagas;
