import { authActions } from "../../../@redux/slices";
import { SmsReqData } from "@types";
import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import styles from "../SignIn.module.scss";
import { useAuth } from "../../../@redux/selectors";
import { useEffect } from "react";

const phoneRegExp = /^(\+98|0)?9\d{9}$/g ;

const createStoreValidation = Yup.object().shape({
  mobile: Yup.string().required("شماره موبایل را وارد نمایید").matches(phoneRegExp, 'شماره موبایل صحیح نیست'),
  smsCode: Yup.string().required("کد را وارد کنید")
});
const init: SmsReqData = {
    mobile: "",
    smsCode: ""
};

const Sms = () => {
  const dispatch = useDispatch();
  const { btnLoding , userData } = useAuth();
  return (
      <div className={`${styles.forms_container_div} w-100 h-100`}>
        <div className={`shadow mb-6 text-center ${styles.py_5} ${styles.px_10}`}>
          <h1 className="m-0">ورود به پنل فروشگاه</h1>
        </div>
        <Formik
          validationSchema={createStoreValidation}
          enableReinitialize
          initialValues={{
            mobile: "",
            smsCode: ""
          }}
          onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
            dispatch(authActions.salerSms(values))
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <div className={`${styles.sms_container} ${styles.px_20}`}>
                <div className="row">
                  <div className={`col-md-12 ${styles.px_20}`}>
                    <div className="mb-4">
                      <label htmlFor="mobile">شماره موبایل</label>
                      {errors.mobile && touched.mobile && (
                        <span className="text-danger mx-4">
                          {errors.mobile}
                        </span>
                      )}
                      <Field
                        id="mobile"
                        name="mobile"
                        className="formInput_login w-100"
                        placeholder="شماره موبایل"
                        value={values.mobile}
                      />
                    </div>
                    <div className="mb-4">
                      <label>کد اس ام اس</label>
                      {errors.smsCode && touched.smsCode && (
                        <span className="text-danger mx-4">
                          {errors.smsCode}
                        </span>
                      )}
                      <Field
                        id="smsCode"
                        name="smsCode"
                        type="smsCode"
                        className="formInput_login w-100"
                        placeholder="کد"
                        value={values.smsCode}
                      />
                    </div>
                    <div className="w-100 ">
                      <button
                        type="submit"
                        className="btn my-3 w-100 btn-sm btn-success cursor-pointer"
                      >
                        ورود
                      </button>
                      <button
                        type="button"
                        className="btn w-100 btn-sm btn-primary cursor-pointer"
                        onClick={()=> dispatch(authActions.setSms(false))}
                      >
                        بازگشت به ثبت نام
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className={`${styles.loading_container} ${btnLoding ? styles.loading_container_active : styles.loading_container_deactive}`}>
          <div className={`${styles.loading_container_div} spinner-border`} role="status">
            <span className="sr-only"></span>
          </div>
        </div>
        
      </div>
  );
};

export default Sms;
