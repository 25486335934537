import React, { useEffect, useState } from 'react';
import styles from './tablePaging.module.scss';

interface IProps {
  canNext?: boolean;
  defaultPageSize?: number;
  pageSizeOptions?: number[];
  showPageSizeOptions?: boolean;
  links?: {
    url: string | null;
    label: string;
    active: boolean;
  }[];
  onPageChange: (pageLink: string) => void;
  onPageSizeChange: (pageSize: number) => void;
}

const DataTablePagination = ({
  pageSizeOptions,
  defaultPageSize = 10,
  canNext,
  showPageSizeOptions,
  links,
  onPageChange,
  onPageSizeChange
}: IProps) => {
  const [pageLink, setPageLink] = useState<string>('');

  useEffect(() => {
    onPageChange(pageLink);
  }, [pageLink, onPageChange]);

  return (
    <div className={styles.paging_container}>
      {links?.map((link) => {
        return (
          <button
            className={link.active ? styles.active : ''}
            disabled={!link.url}
            onClick={() => setPageLink(link.url || '')}
            dangerouslySetInnerHTML={{ __html: link.label }}
          ></button>
        );
      })}
    </div>
  );
};
export default DataTablePagination;
