import { IStoreSchedule, IUpdateStoreScheduleReq} from "../types";
import { urls } from "../utils";
import http from "./sevices";
import config from "./config.json"

export const setStoreSchedule = (id: string, data: IStoreSchedule) => {
    return http
      .post(`${config.AdminApiUrl}${urls.storeSchedule}/store`, data)
      .then((response) => response.data)
      .catch((err) => err.response.data);
  };
export const getStoreScheduleList = () => {
    return http
      .get(`${config.AdminApiUrl}${urls.storeSchedule}/list`)
      .then((response) => response.data)
      .catch((err) => err.response.data);
};
export const updateStoreScheduleList = (payload : IUpdateStoreScheduleReq) => {
  return http
    .put(`${config.AdminApiUrl}${urls.storeSchedule}/update/${payload.id}` , payload.data)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const deletesStoreSchedule = (id : string) => {
  return http
    .delete(`${config.AdminApiUrl}${urls.storeSchedule}/delete/${id}`)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};