import { authActions } from "../../../@redux/slices";
import { RegisterReqData } from "@types";
import { Field, Form, Formik } from "formik";
import { control } from "leaflet";
// import TreeSelect from "rc-tree-select";
// import "rc-tree-select/assets/index.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import Map from "src/app/components/common/map/Map";
import MediaLibraryButton from "src/app/components/common/MediaLibrary";
// import Map from "@src/components/general/map";
import * as Yup from "yup";
import styles from "../SignIn.module.scss";
import { useAuth } from "../../../@redux/selectors";
import { Button } from "react-bootstrap";

const phoneRegExp = /^(\+98|0)?9\d{9}$/g ;

const createStoreValidation = Yup.object().shape({
  first_name: Yup.string().required("نام را وارد نمایید"),
  last_name: Yup.string().required("نام خانوادگی را وارد نمایید"),
  email: Yup.string()
    .required("ایمیل را وارد کنید")
    .email("ایمیل وارد شده در قالب صحیح نمی باشد"),
  mobile: Yup.string().required("شماره موبایل را وارد نمایید").matches(phoneRegExp, 'شماره موبایل صحیح نیست'),
  address: Yup.string().required("آدرس فروشگاه را وارد نمایید"),
  postal_code: Yup.string().required("کد پستی فروشگاه را وارد نمایید"),
  name: Yup.string().required("نام فروشگاه را وارد کنید."),
  shipping_radius: Yup.number().nullable().required("شعاع کاری فروشگاه را وارد کنید"),
  lat: Yup.number().nullable().required("موقعیت فروشگاه را انتخاب کنید"),
  lng: Yup.number().nullable().required("موقعیت فروشگاه را انتخاب کنید"),
  categories: Yup.array().nullable().min(1, "حداقل یک مورد را انتخاب کنید"),
  password: Yup.string().required("رمزعبور را وارد کنید").min(6 , " حداقل 6 کاراکتر "),
  password_confirmation: Yup.string().required("تکرار رمز عبور را وارد نمایید").min(6 , " حداقل 6 کاراکتر "),
});
const init: RegisterReqData = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  name: "",
  address: "",
  postal_code: "",
  lat: null,
  lng: null,
  shipping_radius: "",
  categories: [null],
  password: "",
  password_confirmation: ""
};

const SignUp = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<RegisterReqData>(init);
  const [categoriesList, setCategoriesList] = useState<any[]>();
  const { btnLoding , categories , userData  } = useAuth();
  useEffect(()=>{
    dispatch(
      authActions.getCategoriesForRegister()
    );
  },[false])
  return (
      <div className={`${styles.forms_container_div} h-100`}>
        <div className={`shadow mb-6 text-center ${styles.py_5} ${styles.px_10}`}>
          <h1 className="m-0">ثبت نام فروشندگان</h1>
        </div>
        <Formik
          validationSchema={createStoreValidation}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
            dispatch(
              authActions.salerRegister(values)
            );
          }}
        >
          {({
            values,
            touched,
            errors,
            submitForm,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form id="form">
              <div className={styles.px_20}>
                <div className="row">
                  <div className={`col-md-6 col-sm-12 ${styles.px_20}`}>
                    <div className="mb-4">
                      <label htmlFor="first_name">نام مدیر فروشگاه</label>
                      {errors.first_name && touched.first_name && (
                        <span className="text-danger mx-4">
                          {errors.first_name}
                        </span>
                      )}
                      <Field
                        id="first_name"
                        name="first_name"
                        className="formInput_login w-100"
                        placeholder="نام مدیر فروشگاه"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="last_name">
                        نام خانوادگی مدیر فروشگاه
                      </label>
                      {errors.last_name && touched.last_name && (
                        <span className="text-danger mx-4">
                          {errors.last_name}
                        </span>
                      )}
                      <Field
                        id="last_name"
                        name="last_name"
                        className="formInput_login w-100"
                        placeholder="نام خانوادگی مدیر فروشگاه"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="email">ایمیل</label>
                      {errors.email && touched.email && (
                        <span className="text-danger mx-4">{errors.email}</span>
                      )}
                      <Field
                        id="email"
                        name="email"
                        className="formInput_login w-100"
                        placeholder="email"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="mobile">شماره موبایل</label>
                      {errors.mobile && touched.mobile && (
                        <span className="text-danger mx-4">
                          {errors.mobile}
                        </span>
                      )}
                      <Field
                        id="mobile"
                        name="mobile"
                        className="formInput_login w-100"
                        placeholder="شماره موبایل"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="name">نام فروشگاه</label>
                      {errors.name && touched.name && (
                        <span className="text-danger mx-4">{errors.name}</span>
                      )}
                      <Field
                        id="name"
                        name="name"
                        className="formInput_login w-100"
                        placeholder="نام فروشگاه"
                      />
                    </div>
                  </div>
                  <div className={`col-md-6 col-sm-12 ${styles.px_20}`}>
                  <div className="mb-4">
                      <label htmlFor="categories">حوزه های فعالیت</label>
                      {errors.categories && touched.categories && (
                        <span className="text-danger mx-4">
                          {errors.categories}
                        </span>
                      )}
                      <ReactSelect 
                        className="w-100" 
                        name="categories" 
                        isMulti={true}
                        value={categoriesList && categoriesList}
                        getOptionLabel={(item)=> item.name}
                        getOptionValue={(item)=> item.id}
                        onChange={(data: any) => {
                          let arr: any = [];
                          data.map((item: any) => { arr.push(item.id)});
                          setFieldValue("categories" ,arr)
                        }}
                        options={categories}
                        styles={{
                          control: styles => ({ ...styles, 
                            backgroundColor: '#fff',
                            margin: "5px 0px",
                            background: "#fff",
                            color: "black",
                            fontWeight: "bolder",
                            transition: "all .4s",
                            borderRadius: "0px",
                            border: "1px solid #0000005f",
                            boxShadow: "none !important"
                          }),
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            return {
                              ...styles,
                              backgroundColor: isFocused ? '#0000001f' : isSelected && isFocused ? '#0000001f' : '#fff',
                              color: 'black',
                          }
                          },
                          menu: styles => ({ ...styles, 
                            backgroundColor: '#fff',
                            border: "1px solid #0000005f",
                            margin: "5px 0px",
                            background: "#fff",
                            color: "black",
                            fontWeight: "bolder",
                            transition: "all .4s",
                            borderRadius: "0px",
                            boxShadow: "none !important",
                            padding: "0px"
                          }),
                          dropdownIndicator:styles => ({ ...styles, 
                            color: "hsl(0deg 0% 41.73%)"
                          })
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="postal_code">کد پستی</label>
                      {errors.postal_code && touched.postal_code && (
                        <span className="text-danger mx-4">
                          {errors.postal_code}
                        </span>
                      )}
                      <Field
                        id="postal_code"
                        name="postal_code"
                        className="formInput_login w-100"
                        placeholder="کد پستی"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="shipping_radius">
                        شعاع کاری فروشگاه
                      </label>
                      {errors.shipping_radius && touched.shipping_radius && (
                        <span className="text-danger mx-4">
                          {errors.shipping_radius}
                        </span>
                      )}
                      <Field
                        type="number"
                        id="shipping_radius"
                        name="shipping_radius"
                        className="formInput_login w-100"
                        placeholder="( کیلومتر )"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="password">رمز عبور</label>
                      {errors.password && touched.password && (
                        <span className="text-danger mx-4">
                          {errors.password}
                        </span>
                      )}
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        className="formInput_login w-100"
                        placeholder="رمز عبور"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="password_confirmation">تکرار رمز عبور</label>
                      {errors.password_confirmation && touched.password_confirmation && (
                        <span className="text-danger mx-4">
                          {errors.password_confirmation}
                        </span>
                      )}
                      <Field
                        id="password_confirmation"
                        name="password_confirmation"
                        type="password"
                        className="formInput_login w-100"
                        placeholder="تکرار رمز عبور"
                      />
                    </div>
                  </div>
                  <div className={`col-md-12 ${styles.px_20}`}>
                    <div className="mb-4">
                      <label htmlFor="address">آدرس فروشگاه</label>
                      {errors.address && touched.address && (
                        <span className="text-danger mx-4">
                          {errors.address}
                        </span>
                      )}
                      <Field
                        id="address"
                        name="address"
                        className="formInput_login w-100"
                        placeholder="آدرس فروشگاه"
                        style={{minHeight: "200px" , maxHeight: "200px"}}
                        as="textarea"
                      />
                    </div>
                    {/* <div className="mb-4">
                      <label>لوگوی فروشگاه</label>
                      {errors.logo && (
                          <span className="text-danger mx-4">
                            {errors.logo as string}
                          </span>
                        )}
                      <MediaLibraryButton
                        modalId="logo"
                        height="100px"
                        defaultFiles={values.logo || []}
                        isMultiSelect={true}
                        onChange={(f) => {
                          setFieldValue('logo', f);
                        }}
                        onSubmit={(f) => {
                          setFieldValue('logo', f);
                        }}
                      />
                    </div> */}
                    <div className="mb-4">
                      <label>موقعیت فروشگاه</label>
                      {(errors.lat || errors.lng) &&
                        (touched.lat || touched.lng) && (
                          <span className="text-danger mx-4">
                            {errors.lat || errors.lng}
                          </span>
                        )}
                      <Map
                        lat={values.lat || 35.7219}
                        lng={values.lng || 51.3347}
                        onChange={({ lat, lng }) => {
                          setFieldValue("lat", lat);
                          setFieldValue("lng", lng);
                        }}
                      />
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                      <Button
                        onClick={() => submitForm}
                        type="submit"
                        form="form"
                        className="btn my-5 w-25 btn-sm btn-success cursor-pointer"
                      >
                        ثبت نام
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className={`${styles.loading_container} ${btnLoding ? styles.loading_container_active : styles.loading_container_deactive}`}>
          <div className={`${styles.loading_container_div} spinner-border`} role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </div>
  );
};

export default SignUp;
