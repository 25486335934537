import { Formik, Form, Field, FieldArray } from 'formik';
import { Card } from 'react-bootstrap';
import Button from 'src/app/components/common/Button/Button';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { ISetStoreScheduleReq, IStore, IStoreSchedule } from '@types';
import { useDispatch } from 'react-redux';
import { settingsActions } from 'src/app/@redux/slices';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useSettings } from 'src/app/@redux';
import Loading from '../../../../../src/app/components/general/Loading';
import './style.scss';
import moment, { Moment } from 'moment';
import swal from 'sweetalert';

interface IInit {
  data: {
    [key: string]: IStoreSchedule[];
  };
}

const TransportSettings = () => {
  const dispatch = useDispatch();
  const { btnLoading, isLoading, scheduleList } = useSettings();
  const [
    selectedScheduleIndexForCreateOrEdit,
    setSelectedScheduleIndexForCreateOrEdit
  ] = useState<number | null>(null);
  const [selectedScheduleIdForDelete, setSelectedScheduleIdForDelete] =
    useState<number>(0);
  const [day, setDay] = useState<string>('');

  const data: IInit = {
    data: {
      SA: [
        {
          start: '',
          end: '',
          stop: '',
          price: '',
          title: ''
        }
      ],
      SU: [
        {
          start: '',
          end: '',
          stop: '',
          price: '',
          title: ''
        }
      ],
      MO: [
        {
          start: '',
          end: '',
          stop: '',
          price: '',
          title: ''
        }
      ],
      TU: [
        {
          start: '',
          end: '',
          stop: '',
          price: '',
          title: ''
        }
      ],
      WE: [
        {
          start: '',
          end: '',
          stop: '',
          price: '',
          title: ''
        }
      ],
      TH: [
        {
          start: '',
          end: '',
          stop: '',
          price: '',
          title: ''
        }
      ],
      FR: [
        {
          start: '',
          end: '',
          stop: '',
          price: '',
          title: ''
        }
      ]
    }
  };

  const [initialValues, setInitialValues] = useState(data);
  useEffect(() => {
    dispatch(settingsActions.getStoreSchedules());
  }, []);

  useEffect(() => {
    scheduleList &&
      setInitialValues((prevState) => ({
        ...prevState,
        data: { ...data.data, ...scheduleList }
      }));
  }, [scheduleList]);

  return (
    <Card>
      {isLoading && <Loading />}
      <div className="p-3">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => {}}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => {
            return (
              <Form>
                <FieldArray
                  name="data"
                  render={({ push, remove }) => {
                    return (
                      <div className="">
                        {Object.keys(values.data).map((item, index) => {
                          let fa_day: string = '';
                          switch (item) {
                            case 'SA':
                              fa_day = 'شنبه';
                              break;

                            case 'SU':
                              fa_day = 'یکشنبه';
                              break;

                            case 'MO':
                              fa_day = 'دوشنبه';
                              break;

                            case 'TU':
                              fa_day = 'سه شنبه';
                              break;

                            case 'WE':
                              fa_day = 'چهارشنبه';
                              break;

                            case 'TH':
                              fa_day = 'پنجشنبه';
                              break;

                            case 'FR':
                              fa_day = 'جمعه';
                              break;

                            default:
                              break;
                          }
                          return (
                            <div className="p-3 border mb-6" key={item}>
                              <h3>{fa_day}</h3>
                              {values.data[item].map(
                                (
                                  transportItem: IStoreSchedule,
                                  inx: number
                                ) => {
                                  const { id, start, end, stop, price } =
                                    transportItem;
                                  return (
                                    <div
                                      className="p-3 mb-3 d-flex flex-column border"
                                      key={inx}
                                    >
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column w-100 m-lg-2">
                                          <label>ساعت شروع</label>
                                          <TimePicker
                                            className="time-picker border formInput"
                                            popupClassName="time-picker-popup"
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                `data.${item}.${inx}.start`,
                                                `${e._d.getHours()}:${e._d.getMinutes()}`
                                              );
                                            }}
                                            value={
                                              values.data[item][inx].start
                                                ? moment(
                                                    values.data[item][inx]
                                                      .start,
                                                    'HH:mm'
                                                  )
                                                : undefined
                                            }
                                            showSecond={false}
                                            name="start"
                                            placeholder="ساعت شروع"
                                          />
                                        </div>
                                        <div className="d-flex flex-column w-100 m-lg-2">
                                          <label>ساعت پایان</label>
                                          <TimePicker
                                            className="time-picker border formInput"
                                            popupClassName="time-picker-popup"
                                            name="end"
                                            onChange={(e: any) =>
                                              setFieldValue(
                                                `data.${item}.${inx}.end`,
                                                `${e._d.getHours()}:${e._d.getMinutes()}`
                                              )
                                            }
                                            value={
                                              values.data[item][inx].end
                                                ? moment(
                                                    values.data[item][inx].end,
                                                    'HH:mm'
                                                  )
                                                : undefined
                                            }
                                            showSecond={false}
                                            placeholder="ساعت پایان"
                                          />
                                        </div>
                                        <div className="d-flex flex-column w-100 m-lg-2">
                                          <label>ساعت توقف</label>
                                          <TimePicker
                                            className="time-picker border formInput"
                                            popupClassName="time-picker-popup"
                                            name="stop"
                                            onChange={(e: any) =>
                                              setFieldValue(
                                                `data.${item}.${inx}.stop`,
                                                `${e._d.getHours()}:${e._d.getMinutes()}`
                                              )
                                            }
                                            value={
                                              values.data[item][inx].stop
                                                ? moment(
                                                    values.data[item][inx].stop,
                                                    'HH:mm'
                                                  )
                                                : undefined
                                            }
                                            showSecond={false}
                                            placeholder="ساعت توقف"
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column w-100 m-lg-2">
                                          <label>عنوان</label>
                                          <Field
                                            className="formInput py-3"
                                            name="title"
                                            onChange={(e: any) =>
                                              setFieldValue(
                                                `data.${item}.${inx}.title`,
                                                e.target.value
                                              )
                                            }
                                            value={
                                              values.data[item][inx].title || ''
                                            }
                                            placeholder="عنوان"
                                          />
                                        </div>
                                        <div className="d-flex flex-column w-100 m-lg-2">
                                          <label>هزینه حمل و نقل</label>
                                          <Field
                                            type="number"
                                            className="formInput py-3"
                                            name="price"
                                            onChange={(e: any) =>
                                              setFieldValue(
                                                `data.${item}.${inx}.price`,
                                                e.target.value
                                              )
                                            }
                                            value={
                                              values.data[item][inx].price || ''
                                            }
                                            placeholder="هزینه حمل و نقل"
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-end">
                                        <Button
                                          icon="delete"
                                          type="text"
                                          text="حذف"
                                          className="ms-3"
                                          isLoading={
                                            id === selectedScheduleIdForDelete
                                              ? btnLoading
                                              : false
                                          }
                                          onClick={() => {
                                            if (!id) {
                                              setFieldValue(
                                                `data.${item}`,
                                                values.data[item].filter(
                                                  (el, i) => inx !== i && el
                                                )
                                              );
                                            } else {
                                              swal({
                                                dangerMode: true,
                                                text: 'از حذف روش حمل و نقل اطمینان دارید؟',
                                                buttons: ['انصراف', 'حذف'],
                                                icon: 'warning'
                                              }).then((result) => {
                                                if (result) {
                                                  setSelectedScheduleIdForDelete(
                                                    id
                                                  );
                                                  dispatch(
                                                    settingsActions.deleteStoreSchedule(
                                                      id.toString()
                                                    )
                                                  );
                                                }
                                              });
                                            }
                                          }}
                                        />
                                        <Button
                                          icon={id ? 'edit' : undefined}
                                          type="text"
                                          text={!id ? 'ثبت' : 'ویرایش'}
                                          className="ms-3"
                                          isLoading={
                                            inx ===
                                              selectedScheduleIndexForCreateOrEdit &&
                                            day === item
                                              ? btnLoading
                                              : false
                                          }
                                          onClick={() => {
                                            setSelectedScheduleIndexForCreateOrEdit(
                                              inx
                                            );
                                            setDay(item);
                                            if (!id) {
                                              const data: ISetStoreScheduleReq =
                                                {
                                                  id: '2',
                                                  data: {
                                                    ...transportItem,
                                                    price: Number(price),
                                                    /* ceil: Number(ceil), */
                                                    day: item
                                                  }
                                                };
                                              dispatch(
                                                settingsActions.createStoreSchedule(
                                                  data.data
                                                )
                                              );
                                            } else {
                                              const data: ISetStoreScheduleReq =
                                                {
                                                  id: id.toString(),
                                                  data: {
                                                    ...transportItem,
                                                    price: Number(price),
                                                    /* ceil: Number(ceil), */
                                                    day: item
                                                  }
                                                };
                                              dispatch(
                                                settingsActions.updateStoreSchedule(
                                                  {
                                                    data: data.data,
                                                    id: id
                                                  }
                                                )
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                              <Button
                                icon="add"
                                text="اضافه کردن آیتم جدید"
                                onClick={() => {
                                  setFieldValue(`data.${item}`, [
                                    ...values.data[item],
                                    {
                                      start: '',
                                      end: '',
                                      stop: '',
                                      price: '',
                                      title: ''
                                    }
                                  ]);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Card>
  );
};

export default TransportSettings;
