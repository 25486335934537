import React, { useEffect, useState } from 'react';
import styles from './tablePaging.module.scss';

// todo delete faker package

import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table';
import {
  ITableColumns,
  ITableData,
  ITableDataCollection
} from '../../../types/table.types';
import DataTablePagination from './DataTablePagination';

interface IProps<T> {
  data?: ITableData<T>;
  dataCollection?: ITableDataCollection<T>;
  columns: ITableColumns[];
  pageSize: number;
  headerTitle?: string;
  myProductBarcodes?: string[];
  element?: () => JSX.Element;
  fetchData?: ({ link, pageSize }: { link: string; pageSize: number }) => void;
}
const TablePaging = <T extends unknown>(props: IProps<T>) => {
  const [pageLink, setPageLink] = useState<string>();
  
  // const [data, setData] = useState<ITableData<T> | any>();

  // useEffect(()=>{
  //   if (props.dataCollection) {
  //     const newData = {
  //       data: props.dataCollection.data,
  //       next_page_url: props.dataCollection.links.next,
  //       prev_page_url: props.dataCollection.links.prev,
  //       first_page_url: props.dataCollection.links.first,
  //       last_page_url: props.dataCollection.links.last,
  //       ...props.dataCollection.meta
  //     };
  //     setData(newData);
  //   } else {
  //     setData(props.data);
  //   }

  // },[props.data , props.dataCollection])

  React.useEffect(() => {
    // if (setPageIndexFor) {
    //   setPageIndexFor(pageIndex);
    // }
    // if (setPageSizeFor) {
    //   setPageSizeFor(pageSize);
    // }
    props.fetchData &&
      pageLink &&
      props.fetchData({
        link: pageLink,
        pageSize: props.pageSize
      });
  }, [pageLink]);

  // Create the table and pass your options
  const table = useReactTable({
    data: props.data?.data
      ? props.data?.data
      : props.dataCollection?.data || [],
    columns: props.columns as any,
    manualPagination: true, // Tell the usePagination
    // hook that we'll handle our own data fetching
    // This means we'll also have to provide our own
    // pageCount.
    pageCount: props.data?.last_page || props.dataCollection?.meta.last_page,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  // Manage your own state
  const [state, setState] = React.useState(table.initialState);

  // Override the state managers for the table to your own
  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: setState,
    // These are just table options, so if things
    // need to change based on your state, you can
    // derive them here

    // Just for fun, let's debug everything if the pageIndex
    // is greater than 2
    debugTable: state.pagination.pageIndex > 2
  }));

  return (
    <div className="p-2">
      <div className={`card`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              {props.headerTitle && props.headerTitle }
            </span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            {props.element  ? props.element() : <></>}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed p-5 rounded table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}

              <thead >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr className="fw-bold text-muted">
                    {/* <th>#</th> */}
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="min-w-70px"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody>
                {table.getRowModel().rows.map((row) => {
                  {
                    var products = row.original as any;
                  }
                  return (
                    <tr 
                    className={
                      props.myProductBarcodes?.find((bar: any) => products.barcode == bar) && "bg-secondary"
                    }
                    >
                      {/* <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input widget-9-check"
                            type="checkbox"
                            value="1"
                          />
                        </div>
                      </td> */}
                      {row.getVisibleCells().map((cell) => (
                        <td
                          className='px-3'
                          key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <DataTablePagination
        canNext
        defaultPageSize={10}
        showPageSizeOptions={true}
        pageSizeOptions={[10, 20, 30, 40, 50, 60]}
        onPageSizeChange={(s) => console.log(s)}
        onPageChange={(p) => {
          setPageLink(p);
        }}
        links={props.data?.links || props.dataCollection?.meta.links}
      />
    </div>
  );
};
export default TablePaging;
