import styles from "./SignIn.module.scss";
import Switch from "./Switch";
import Login from "./components/Login";
import { useEffect, useState } from "react";
import SignUp from "./components/SignUp";
import { toAbsoluteUrl } from "src/_metronic/helpers";
import Sms from "./components/Sms";
import { useAuth } from "../../@redux/selectors";
import { useDispatch } from "react-redux";
import { authActions } from "../../@redux/slices";

const SignIn = () => {
  const [activeButton , setActiveButton] = useState("Login");
  const { sms } = useAuth();
  const dispatch = useDispatch();
  return (
    <div>
        <div className={styles.signIn_bg}>
            <img src={toAbsoluteUrl('/media/auth/auth-bg.jpg')} />
        </div>
        {!sms ?
        <div className={styles.signIn_container}>
          <Switch activeButton={activeButton} setActiveButton={setActiveButton}/>
          <div className={styles.forms_container}>
            {activeButton == "Login" ? <Login /> : <SignUp />}
          </div>
        </div>
        :
        <div className={styles.send_sms_container}>
          <Sms />
        </div>
        }
    </div>
  );
};

export default SignIn;
