import { useNavigate, useParams } from 'react-router-dom';
import { useOrder } from '../../@redux/selectors';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { orderActions } from '../../@redux/slices';
import Loading from 'src/app/components/general/Loading';

type Props = {
  edit?: boolean;
};

const OrderReport = (props: Props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { btnLoding , orderReport } = useOrder();

  useEffect(() => {
    dispatch(orderActions.getOrderReport());
  },[])
  return (
    <div>
      {btnLoding && <Loading />}
      <div className='card'>
        <div className='card-body d-flex flex-wrap'>
        <div className='col-12 px-2 pb-5'>
          <h1>استعلام</h1>
        </div>
          <div className='col-md-6 col-12 p-2'>
            <span> تعداد سفارشات: ${orderReport?.data.report.number_of_orders?? ''}</span>
          </div>
          <div className='col-md-6 col-12 p-2'>
            <span> تعداد سفارشات لغو شده: ${orderReport?.data.report.number_of_canceled_orders?? ''}</span>
          </div>
          <div className='col-md-6 col-12 p-2'>
            <span> تعداد سفارشات پرداخت شده: ${orderReport?.data.report.number_of_cashed_orders?? ''}</span>
          </div>
          <div className='col-md-6 col-12 p-2'>
            <span>مجموع پرداختی ها: ${orderReport?.data.report.sum_of_final_prices?? ''}</span>
          </div>
          <div className='col-12 p-2'>
            <span> بهترین فروش: </span>
            <span>نام: ${orderReport?.data.report.best_seller?.name?? ''}</span>
            <span>بارکد: ${orderReport?.data.report.best_seller?.barcode?? ''}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderReport;