import clsx from 'clsx';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { KTSVG } from 'src/_metronic/helpers';

import styles from './button.module.scss';

interface IProps {
  text?: string;
  color?: 'primary' | 'white' | 'gray' | 'dark' | 'secondary' | 'danger';
  type?: 'text' | 'outlined' | 'contained';
  children?: ReactNode;
  className?: string;
  width?: string | number;
  height?: string | number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  btnType?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  icon?: 'edit' | 'delete' | 'add' | 'more' | 'settings';
  title?: string;
}

const Button = ({
  text,
  type = 'contained',
  children,
  className,
  color = 'primary',
  width,
  height,
  style,
  onClick,
  btnType = 'button',
  disabled,
  isLoading,
  fullWidth,
  icon,
  title
}: IProps) => {
  const _renderIcon = useMemo(() => {
    switch (icon) {
      case 'edit':
        return (
          <div className="d-flex align-items-center">
            {text && <span className="mx-2">{text}</span>}
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </div>
        );
      case 'delete':
        return (
          <div className="d-flex align-items-center">
            {text && <span className="mx-2">{text}</span>}
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </div>
        );

      case 'add':
        return (
          <div className="d-flex align-items-center">
            {text && <span className="mx-2">{text}</span>}
            <KTSVG
              path="/media/icons/duotune/general/gen035.svg"
              className="svg-icon-3"
            />
          </div>
        );
      case 'more':
        return (
          <div className="d-flex align-items-center">
            {text && <span className="mx-2">{text}</span>}
            <KTSVG
              path="/media/icons/duotune/general/gen038.svg"
              className="svg-icon-3"
            />
          </div>
        );
      case 'settings':
        return (
          <div className="d-flex align-items-center">
            {text && <span className="mx-2">{text}</span>}
            <KTSVG
              path="/media/icons/duotune/coding/cod001.svg"
              className="svg-icon-3"
            />
          </div>
        );
      default:
        return icon;
    }
  }, [icon]);

  return (
    <button
      className={clsx(
        className,
        type === 'text' &&
          'btn btn-icon btn-bg-light btn-active-color-primary btn-sm',
        type === 'contained' && 'btn btn-sm btn-primary'
      )}
      style={{
        width: fullWidth ? '100%' : width,
        minWidth: text && 100,
        height: height,
        ...style
      }}
      onClick={onClick}
      type={btnType}
      disabled={disabled}
      title={title}
    >
      {isLoading ? (
        <span className="indicator-progress" style={{ display: 'block' }}>
          لطفا صبر کنید...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      ) : (
        _renderIcon || children || text
      )}
    </button>
  );
};

export default Button;
