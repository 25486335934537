import { IPaginateParams, addProductReq, changeMyStocks, productCatePaginate, storeStock } from "../types";
import { urls } from "../utils";
import http from "./sevices";
import config from "./config.json"
export const getCateListServices = (payload: productCatePaginate<IPaginateParams>) => {
  if(payload.paginate.link){
    return http
    .post(
      `${payload.paginate.link}&per_page=${payload.paginate.pageSize}` , payload.search
     ).then((response) => response.data)
     .catch((err) => err.response.data);
    
  }else{
    return http
    .post(
      `${config.AdminApiUrl}${urls.getCateProduct}/${payload.slug}`, payload.search
     ).then((response) => response.data)
     .catch((err) => err.response.data);
    
  }
};

export const getBlogs = (params: IPaginateParams) => {
  return http
    .put(
      params.link
        ? `${params.link}&per_page=${params.pageSize}`
        : `${config.AdminApiUrl}${urls.blogGet}?per_page=15`
    )
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const importExelFileServices = (file: File) => {
  return http
    .post(`${config.AdminApiUrl}${urls.exelImport}` , file)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const categoriesServices = () => {
  return http
    .post(`${config.AdminApiUrl}${urls.categories}`)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const storeStockServices = (data: storeStock) => {
  return http
    .post(`${config.AdminApiUrl}${urls.storeStock}` , data)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const addProductServices = (data: addProductReq) => {
  return http
    .post(`${config.AdminApiUrl}${urls.addProduct}` , data)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const getMyStockServices = () => {
  return http
    .get(`${config.AdminApiUrl}${urls.myStocks}`)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const getMyEditStockServices = (id: number) => {
  return http
    .get(`${config.AdminApiUrl}${urls.stockEdit}/${id}`)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const changeMyStockServices = (payload: changeMyStocks) => {
  return http
    .put(`${config.AdminApiUrl}${urls.stockEdit}/${payload.id}` , payload.data)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};