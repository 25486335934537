import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  FeatureGroup
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import { useMemo, useRef, useState } from 'react';
import { Marker as TMarker } from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import { EditControl } from 'react-leaflet-draw';

interface IProps {
  lng?: number;
  lat?: number;
  onChange: (value: { lat: number; lng: number }) => void;
  className?: string;
  borderRadius?: number;
  isPolygon?: boolean;
}

const Map = ({
  lat = 38.0792,
  lng = 46.2887,
  isPolygon,
  onChange,
  className,
  borderRadius = 10
}: IProps) => {
  const [latlng, setlatlng] = useState({ lat: lat, lng: lng });
  const [polygon, setPolygon] = useState<any>(null);

  function MyComponent() {
    const map = useMapEvents({
      click: (data) => {
        setlatlng(data.latlng);
        isPolygon ? onChange(polygon) : onChange(data.latlng);
      }
    });
    return null;
  }

  const markerRef = useRef<TMarker<any>>(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setlatlng(marker.getLatLng());
        }
      }
    }),
    []
  );
  const [editableFG, setEditableFG] = useState<any>(null);
  const onCreated = (e: any) => {
    setPolygon(e.layer._latlngs);
    const drawnItems = editableFG.leafletElement._layers;
    if (Object.keys(drawnItems).length > 1) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (index > 0) return;
        const layer = drawnItems[layerid];
        editableFG.leafletElement.removeLayer(layer);
      });
    }
  };
  return (
    <MapContainer
      center={latlng}
      zoom={13}
      scrollWheelZoom={true}
      style={{ height: 250, width: '100%', borderRadius: borderRadius }}
      className={className}
    >
      <MyComponent />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {isPolygon && (
        <FeatureGroup
          ref={(featureGroupRef) => {
            setEditableFG(featureGroupRef);
          }}
        >
          <EditControl
            position="topright"
            onCreated={onCreated}
            draw={{
              marker: false,
              circle: false,
              circlemarker: false,
              rectangle: false,
              polygon: true,
              polyline: false
            }}
          />
        </FeatureGroup>
      )}
      {!isPolygon && (
        <Marker
          draggable
          eventHandlers={eventHandlers}
          position={latlng}
          ref={markerRef}
        />
      )}
    </MapContainer>
  );
};

export default Map;
