import { authActions } from "../../../@redux/slices";
import { LoginReqData } from "@types";
import { Field, Form, Formik } from "formik";
import { control } from "leaflet";
// import TreeSelect from "rc-tree-select";
// import "rc-tree-select/assets/index.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import Map from "src/app/components/common/map/Map";
import MediaLibraryButton from "src/app/components/common/MediaLibrary";
// import Map from "@src/components/general/map";
import * as Yup from "yup";
import styles from "../SignIn.module.scss";
import { useAuth } from "../../../@redux/selectors";

const phoneRegExp = /^(\+98|0)?9\d{9}$/g ;

const createStoreValidation = Yup.object().shape({
  mobile: Yup.string().required("شماره موبایل را وارد نمایید").matches(phoneRegExp, 'شماره موبایل صحیح نیست'),
  password: Yup.string().required("رمزعبور را وارد کنید").min(6 , " حداقل 6 کاراکتر "),
});
const init: LoginReqData = {
  mobile: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const { btnLoding } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<LoginReqData>(init);
  const [categoriesList, setCategoriesList] = useState<any[]>([]);


  return (
      <div className={`${styles.forms_container_div} h-100`}>
        <div className="shadow mb-6 text-center pt-6 pb-4 px-10">
          <h1 className="">ورود به پنل فروشگاه</h1>
        </div>
        <Formik
          validationSchema={createStoreValidation}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
            dispatch(authActions.salerLogin(values))
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <div className="px-20">
                <div className="row">
                  <div className="col-md-12 px-20">
                    <div className="mb-4">
                      <label htmlFor="mobile">شماره موبایل</label>
                      {errors.mobile && touched.mobile && (
                        <span className="text-danger mx-4">
                          {errors.mobile}
                        </span>
                      )}
                      <Field
                        id="mobile"
                        name="mobile"
                        className="formInput_login w-100"
                        placeholder="شماره موبایل"
                        value={values.mobile}
                      />
                    </div>
                    <div className="mb-4">
                      <label>رمز عبور</label>
                      {errors.password && touched.password && (
                        <span className="text-danger mx-4">
                          {errors.password}
                        </span>
                      )}
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        className="formInput_login w-100"
                        placeholder="رمز عبور"
                        value={values.password}
                      />
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn my-3 w-25 btn-sm btn-success cursor-pointer"
                      >
                        ورود
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className={`${styles.loading_container} ${btnLoding ? styles.loading_container_active : styles.loading_container_deactive}`}>
          <div className={`${styles.loading_container_div} spinner-border`} role="status">
            <span className="sr-only"></span>
          </div>
        </div>
        
      </div>
  );
};

export default Login;
