import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { category, LoginReqData, LoginResData, RegisterReqData, RegisterResData, SmsReqData } from '@types';

interface IInitial {
  userData?: string | null;
  sms: boolean;
  categories: category[]
  btnLoding: boolean;
}

const initialState: IInitial = {
  btnLoding: false,
  sms: false,
  categories: []
};

export const { actions: authActions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    // setProfile: (
    //   state,
    //   { payload }: PayloadAction<IauthInformation["auth"]>
    // ) => {
    //   state.information.auth = payload;
    //   state.isLoading = false;
    // },
    // salerLogin: (state, { payload }: PayloadAction<LoginReqData>) => {},
    salerRegister: (state, { payload }: PayloadAction<RegisterReqData>) => {},
    salerLogin: (state, { payload }: PayloadAction<LoginReqData>) => {},
    salerSms: (state, { payload }: PayloadAction<SmsReqData>) => {},
    getCategoriesForRegister: (state) => {},


    setSms: (state, { payload }: PayloadAction<boolean>) => {
      state.sms = payload;
    },
    setUserData: (state, { payload }: PayloadAction<string | null>) => {
      state.userData = payload;
    },
    setCategory: (state, { payload }: PayloadAction<category[]>) => {
      state.categories = payload;
    },
    setBtnLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.btnLoding = payload;
    }
  }
});
