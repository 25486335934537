import { orderActions } from "../slices";
import { getOrdersList, IPaginateParams, ITableDataCollection, orderReport, ordersListPaginate, ordersResData } from "@types";
import { put, takeLeading } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { store } from "../store";
import axios from "axios";
import { getOrdersListServices, orderReportServices, searchOrdersCodeServices, searchOrdersMobileServices, searchOrdersStatusServices, searchOrdersTimeServices } from "src/app/services/orders.services";


function* getOrderList({ payload }: PayloadAction<ordersListPaginate<IPaginateParams>>) {
  yield;
  try {
    store.dispatch(orderActions.setBtnLoading(true));
    const result: ITableDataCollection<getOrdersList> = yield getOrdersListServices(payload);
    if (result) {
      store.dispatch(orderActions.setOrdersList(result))
      store.dispatch(orderActions.setBtnLoading(false));
    } else {
      toast.error("مشکلی پیش آمده است");
      store.dispatch(orderActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(orderActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}

function* searchOrdersMobile({ payload }: PayloadAction<{key: string}>) {
  yield;
  try {
    store.dispatch(orderActions.setBtnLoading(true));
    const result: ordersResData<ITableDataCollection<getOrdersList>> = yield searchOrdersMobileServices(payload);
    if (result.status) {
      result.data && store.dispatch(orderActions.setOrdersList(result.data));
      store.dispatch(orderActions.setBtnLoading(false));
    } else {
      toast.error(result.message);
      store.dispatch(orderActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(orderActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}
function* searchOrdersCode({ payload }: PayloadAction<{key: number}>) {
  yield;
  try {
    store.dispatch(orderActions.setBtnLoading(true));
    const result: ordersResData<ITableDataCollection<getOrdersList>> = yield searchOrdersCodeServices(payload);
    if (result.status) {
      result.data && store.dispatch(orderActions.setOrdersList(result.data));
      store.dispatch(orderActions.setBtnLoading(false));
    } else {
      toast.error(result.message);
      store.dispatch(orderActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(orderActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}
function* searchOrdersStatus({ payload }: PayloadAction<{shop_status: number}>) {
  yield;
  try {
    store.dispatch(orderActions.setBtnLoading(true));
    const result: ordersResData<ITableDataCollection<getOrdersList>> = yield searchOrdersStatusServices(payload);
    if (result.status) {
      result.data && store.dispatch(orderActions.setOrdersList(result.data));
      store.dispatch(orderActions.setBtnLoading(false));
    } else {
      toast.error(result.message);
      store.dispatch(orderActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(orderActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}
function* searchOrdersTime({ payload }: PayloadAction<{
  start: string,
  end: string
}>) {
  yield;
  try {
    store.dispatch(orderActions.setBtnLoading(true));
    const result: ordersResData<ITableDataCollection<getOrdersList>> = yield searchOrdersTimeServices(payload);
    if (result.status) {
      result.data && store.dispatch(orderActions.setOrdersList(result.data));
      store.dispatch(orderActions.setBtnLoading(false));
    } else {
      toast.error(result.message);
      store.dispatch(orderActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(orderActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}

function* getOrderReport() {
  yield;
  try {
    store.dispatch(orderActions.setBtnLoading(true));
    const result: orderReport = yield orderReportServices();
    if (result.status) {
      store.dispatch(orderActions.setOrderReport(result));
      store.dispatch(orderActions.setBtnLoading(false));
    } else {
      toast.error(result.message);
      store.dispatch(orderActions.setBtnLoading(false));
    }
  } catch (error) {
    store.dispatch(orderActions.setBtnLoading(false));
    toast.error("مشکلی پیش آمده است");
  }
}

const orderSagas = [
  takeLeading(orderActions.getOrderList.toString(), getOrderList),
  takeLeading(orderActions.searchOrdersMobile.toString(), searchOrdersMobile),
  takeLeading(orderActions.searchOrdersCode.toString(), searchOrdersCode),
  takeLeading(orderActions.searchOrdersStatus.toString(), searchOrdersStatus),
  takeLeading(orderActions.searchOrdersTime.toString(), searchOrdersTime),
  takeLeading(orderActions.getOrderReport.toString(), getOrderReport),
];
export default orderSagas;