import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetStoreParam, IScheduleIds, ISetStoreScheduleReq, IStoreSchedule, IUpdateStoreScheduleReq, TransportList } from '../../types';

interface IInitial {
    isLoading: boolean;
    btnLoading: boolean;
    storeSchedules?: { [key: string]: IStoreSchedule[] };
    scheduleList?: TransportList
}

const initialState: IInitial = {
    isLoading: false,
    btnLoading: false,
    scheduleList: {}
};

export const { actions: settingsActions, reducer: settingsReducer } = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    createStoreSchedule: (
        state,
        { payload }: PayloadAction<IStoreSchedule>
      ) => {
        state.btnLoading = true;
    },
    updateStoreSchedule: (state, { payload }: PayloadAction<IUpdateStoreScheduleReq>) => {},
    getStoreSchedules: () => {},
    deleteStoreSchedule: (state, { payload }: PayloadAction<string>) => {},
    setBtnLoading: (state, { payload }: PayloadAction<boolean>) => {
        state.btnLoading = payload;
    },
    setScheduleList: (state, { payload }: PayloadAction<TransportList>) => {
      state.scheduleList = payload;
    },
  }
});