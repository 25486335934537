import styles from './loading.module.scss';
import { Spinner } from 'react-bootstrap';

const Loading = () => {
  return (
    <div className={styles.container}>
      <Spinner animation="border" />
    </div>
  );
};

export default Loading;
