import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Transport from './transport'

type Props = {}
const SettingBreadCrumbs: Array<PageLink> = [
  {
    title: 'تنظیمات',
    path: '/setting',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const Settings = (props: Props) => {
  return (
    <Routes>
        <Route
          path='transport'
          element={
            <>
              <PageTitle breadcrumbs={SettingBreadCrumbs}>حمل و نقل</PageTitle>
              <Transport />
            </>
          }
        />

      <Route index element={<Navigate to='/setting/transport' />} />
    </Routes>
  )
}

export default Settings;
