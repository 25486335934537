import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitial {
  appName: string;
}

const initialState: IInitial = {
  appName: "فروشگاه",
};

export const { actions: globalsActions, reducer: globalsReducer } = createSlice({
  name: "globals",
  initialState: initialState,
  reducers: {
    // setProfile: (
    //   state,
    //   { payload }: PayloadAction<IUserInformation["user"]>
    // ) => {
    //   state.information.user = payload;
    //   state.isLoading = false;
    // },
    // adminLogin: (state, { payload }: PayloadAction<LoginReqData>) => {},
  },
});
