import { put ,takeLeading } from "redux-saga/effects";
import { toast } from "react-toastify";
import { PayloadAction } from "@reduxjs/toolkit";
import { ICRUDRes, ISetStoreScheduleReq, IStoreSchedule, IUpdateStoreScheduleReq, TransportList } from "@types";
import { deletesStoreSchedule, getStoreScheduleList, setStoreSchedule, updateStoreScheduleList } from "src/app/services/settings.services";
import { settingsActions } from "../slices";
import { store } from "../store";

function* createStoreSchedule({
    payload
  }: PayloadAction<ISetStoreScheduleReq>) {
    try {
      const result: ICRUDRes = yield setStoreSchedule(payload.id, payload.data);
      if (result.status) {
        payload.onSuccess?.();
        toast.success(result.message);
        yield put(settingsActions.setBtnLoading(false));
      } else {
        payload.onFail?.();
        toast.error(result.message);
        yield put(settingsActions.setBtnLoading(false));
      }
    } catch (error) {
      toast.error('مشکلی پیش آمده است');
      yield put(settingsActions.setBtnLoading(false));
    }
  }
  function* getStoreSchedules() {
    try {
      yield put(settingsActions.setBtnLoading(true));
      const result: TransportList = yield getStoreScheduleList();
      if (result) {
        yield put(settingsActions.setScheduleList(result));
      } else {
        toast.error('مشکلی پیش آمده است');
        yield put(settingsActions.setBtnLoading(false));
      }
    } catch (error) {
      toast.error('مشکلی پیش آمده است');
      yield put(settingsActions.setBtnLoading(false));
    }
  }

  function* deleteStoreSchedule({payload} : PayloadAction<string>) {
    try {
      yield put(settingsActions.setBtnLoading(true));
      const result: ICRUDRes = yield deletesStoreSchedule(payload);
      if (result.status) {
        toast.success(result.message);
        yield put(settingsActions.setBtnLoading(false));
        store.dispatch(settingsActions.getStoreSchedules())
      } else {
        toast.error(result.message);
        yield put(settingsActions.setBtnLoading(false));
      }
    } catch (error) {
      toast.error('مشکلی پیش آمده است');
      yield put(settingsActions.setBtnLoading(false));
    }
  }

  function* updateStoreSchedule({payload} : PayloadAction<IUpdateStoreScheduleReq>) {
    try {
      yield put(settingsActions.setBtnLoading(true));
      const result: ICRUDRes = yield updateStoreScheduleList(payload);
      if (result.status) {
        toast.success(result.message);
        yield put(settingsActions.setBtnLoading(false));
      } else {
        toast.error(result.message);
        yield put(settingsActions.setBtnLoading(false));
      }
    } catch (error) {
      toast.error('مشکلی پیش آمده است');
      yield put(settingsActions.setBtnLoading(false));
    }
  }
  
const settingSagas = [
  takeLeading(settingsActions.createStoreSchedule.toString(), createStoreSchedule),
  takeLeading(settingsActions.getStoreSchedules.toString(), getStoreSchedules),
  takeLeading(settingsActions.updateStoreSchedule.toString(), updateStoreSchedule),
  takeLeading(settingsActions.deleteStoreSchedule.toString(), deleteStoreSchedule),
];
export default settingSagas;