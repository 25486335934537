import { toAbsoluteUrl } from 'src/_metronic/helpers';
import styles from './SignIn.module.scss';

type Props = {
  setActiveButton: any;
  activeButton: any;
};

const Switch = (props: Props) => {
  return (
    <div className={styles.switch_container}>
      <p className="h1 text-center mb-12 p-5 text-white">پنل فروشندگان</p>
      <div className="px-5 mt-4 d-grid gap-4">
        <div className="w-100 text-center">
          <button
            onClick={() => props.setActiveButton('Login')}
            className={`${
              props.activeButton == 'Login' ? styles.button_active : ''
            }`}
          >
            ورود
          </button>
        </div>
        <div className="w-100 text-center">
          <button
            onClick={() => props.setActiveButton('SignUp')}
            className={`${
              props.activeButton == 'SignUp' ? styles.button_active : ''
            }`}
          >
            ثبت نام
          </button>
        </div>
      </div>
      <div
        className={`${styles.logo_container} p-3 border border-white rounded-circle m-auto mt-5 d-flex justify-content-center align-items-center`}
      >
        <img className="w-100" src={toAbsoluteUrl('/media/auth/logo.png')} />
      </div>
      <div className={`${styles.copy_right} p-3 w-100`}>
        <p className="text-center w-100">
          تمامی حقوق مادی ومعنوی این سایت متعلق به تیم هسته طراحی تب طرح میباشد
          &copy;
        </p>
      </div>
    </div>
  );
};

export default Switch;
