import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getOrdersList, IPaginateParams, ITableDataCollection, orderReport, ordersListPaginate } from '../../types';

interface IInitial {
    btnLoding: boolean;
    ordersList?: ITableDataCollection<getOrdersList>;
    orderReport?: orderReport;
}

const initialState: IInitial = {
    btnLoding: false,
};

export const { actions: orderActions, reducer: orderReducer } = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    getOrderList: (state, { payload }: PayloadAction<ordersListPaginate<IPaginateParams> | {}>) => {},
    getOrderReport: () => {},
    searchOrdersMobile: (state, { payload }: PayloadAction<{key: string}>) => {},
    searchOrdersCode: (state, { payload }: PayloadAction<{key: number}>) => {},
    searchOrdersStatus: (state, { payload }: PayloadAction<{shop_status: number}>) => {},
    searchOrdersTime: (state, { payload }: PayloadAction<{
      start: string,
      end: string
    }>) => {},

    setOrderReport: (state, { payload }: PayloadAction<orderReport>) => {
        state.orderReport = payload;
    },
    setBtnLoading: (state, { payload }: PayloadAction<boolean>) => {
        state.btnLoding = payload;
    },
    setOrdersList: (state, { payload }: PayloadAction<ITableDataCollection<getOrdersList>>) => {
      state.ordersList = payload;
    },
  }
});
