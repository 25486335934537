/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../helpers';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      {/* <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title="داشبورد"
        fontIcon="bi-app-indicator"
      /> */}
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-4 ls-1">
            صفحات
          </span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to="/product"
        title="محصولات"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/art/art008.svg"
      >
        <AsideMenuItem
          to="/product/list"
          title="لیست کل محصولات"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/product/create"
          title="افزودن محصول جدید"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to="/order"
        title="سفارشات من"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/art/art008.svg"
      >
        <AsideMenuItem
          to="/order/list"
          title="لیست سفارشات من"
          hasBullet={true}
        />
        {/* <AsideMenuItem
          to="/order/report"
          title="استعلام"
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to="/settings"
        title="تنظیمات"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/art/art008.svg"
      >
        <AsideMenuItem
          to="/settings/transport"
          title="حمل و نقل"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
    </>
  );
}
