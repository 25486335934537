import { Formik, Form, Field } from 'formik';
import { useEffect, useState , useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/general/Loading';
import { KTSVG } from 'src/_metronic/helpers';
import { category, getOrdersList, ITableColumns } from '@types';
import { useDispatch } from 'react-redux';
import { orderActions } from '../../@redux/slices';
import { useOrder } from '../../@redux/selectors';
import TablePaging from 'src/app/components/common/tablePaging';
import ReactSelect from 'react-select';
import DatePicker from "react-multi-date-picker";
import Modal from './components/Modal';
import OrderModal from './components/Modal';

type Props = {
  edit?: boolean;
};

const OrderList = (props: Props) => {
  const { btnLoding , ordersList } = useOrder();
  const [isOpen , setIsOpen] = useState<getOrdersList | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
      fetchData({});
  },[]);
  // ordersList
  const fetchData = ({
      link,
      pageSize
    }: {
      link?: string;
      pageSize?: number;
    }) => {
      link
        ? dispatch(orderActions.getOrderList({paginate:{ link, pageSize }}))
        : !link && dispatch(orderActions.getOrderList({paginate:{ pageSize }}))
    };

  const columns: ITableColumns[] = [
      { accessorKey: "id", id: 1, header: "آیدی" },
      { accessorKey: "address.phone", id: 2, header: "شماره موبایل" },
      { accessorKey: "prices.final_price", id: 3, header: "پرداختی نهایی" },
      { accessorKey: "prices.pay_method", id: 4, header: "روش پرداخت" },
      {
        header: "گزینه ها",
        accessorKey: "id",
        cell: (props) => <Actions data={props.row.original}  />,
      }
    ];
  const Actions = (props: any) => {
      return (
        <td>
          <div className="d-flex justify-content-end flex-shrink-0">
              <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={() => setIsOpen(props.data)}>
                  <KTSVG
                      path="/media/icons/duotune/general/gen038.svg"
                      className="svg-icon-3"
                  />
              </span>           
          </div>
        </td>
      );
    };
    interface search {
      filter: string,
      mobile?:{
        key: string
      },
      code?:{
        key: number
      },
      time?:{
        start: string,
        end: string
      },
      status?:{
        shop_status: number
      }
    }
    const init: search = {
      filter: "mobile"
    };
  return (
    <div className='my-2'>
      <div className='p-2'> 
          <div className='card p-8'>
            <Formik
              initialValues={init}
              onSubmit={(values)=> {
                if(values.filter == "mobile"){
                  values.mobile && dispatch(orderActions.searchOrdersMobile(values.mobile))
                }else if(values.filter == "code"){
                  values.code && dispatch(orderActions.searchOrdersCode(values.code))
                }else if(values.filter == "status"){
                  values.status && dispatch(orderActions.searchOrdersStatus(values.status))
                }else{
                  values.time && dispatch(orderActions.searchOrdersTime(values.time))
                }
              }}
            >
              {({
                values,
                touched,
                errors,
                submitForm,
                isSubmitting,
                handleSubmit,
                setFieldValue,
              }) =>
              <Form>
                <div className='d-flex flex-wrap'>
                  <div className='col-md-6 col-12 px-3'>
                  <ReactSelect 
                        className="w-100"
                        name="filter" 
                        placeholder="فیلتر با"
                        onChange={(data: any) => {
                          setFieldValue("filter" , data.value)
                        }}
                        options={[
                          {value: "mobile" , label: "شماره موبایل"},
                          {value: "code" , label: "کد محصول"},
                          {value: "status" , label: "وضیعت فروشگاه"},
                          {value: "time" , label: "زمان"},
                        ]}
                      />
                  </div>
                  {values.filter == "mobile" && 
                    <div className='col-md-6 col-12 px-3'>
                      <div className='form_input_search px-5'>
                        <Field type="text" name="mobile.key" placeholder='موبایل...' className="w-100"/>
                        <div className='d-flex align-items-center justify-content-end' onClick={()=>  submitForm()}>
                          <KTSVG
                            path="/media/icons/duotune/general/gen004.svg"
                            className="svg-icon-1"
                          />
                        </div>
                      </div>
                    </div>
                  }
                  <>{console.log(values)}</>
                  {values.filter == "code" && 
                    <div className='col-md-6 col-12 px-3'>
                      <div className='form_input_search px-5'>
                        <Field type="text" name="code.key" placeholder='کد سفارش...' className="w-100"/>
                        <div className='d-flex align-items-center justify-content-end' onClick={()=>  submitForm()}>
                          <KTSVG
                            path="/media/icons/duotune/general/gen004.svg"
                            className="svg-icon-1"
                          />
                        </div>
                      </div>
                    </div>
                  }
                  {values.filter == "time" && 
                    <div className='col-md-6 col-12 px-3 d-flex justify-content-evenly align-items-center'>
                        <DatePicker 
                          onChange={(e: any)=> setFieldValue('time.start' , e.toUnix())} 
                          placeholder='شروع' 
                          style={{
                            backgroundColor:"transparent",
                            height: "30px",
                            borderRadius: "8px",
                            fontSize: "14px",
                            padding: "3px 10px"
                          }}
                        />
                        <DatePicker 
                          onChange={(e: any)=> setFieldValue('time.end' , e.toUnix())} 
                          placeholder='پایان'
                          style={{
                            backgroundColor:"transparent",
                            height: "30px",
                            borderRadius: "8px",
                            fontSize: "14px",
                            padding: "3px 10px"
                          }}
                        />
                        <div className='d-flex align-items-center justify-content-end cursor_pointer' onClick={()=>  submitForm()}>
                          <KTSVG
                            path="/media/icons/duotune/general/gen004.svg"
                            className="svg-icon-1"
                          />
                        </div>
                    </div>
                  }
                  {values.filter == "status" && 
                    <div className='col-md-6 col-12 px-3'>
                      <div className='form_input_search px-5'>
                        <Field type="text" name="status.shop_status" placeholder='وضیعت سفارش...' className="w-100"/>
                        <div className='d-flex align-items-center justify-content-end' onClick={()=>  submitForm()}>
                          <KTSVG
                            path="/media/icons/duotune/general/gen004.svg"
                            className="svg-icon-1"
                          />
                        </div>
                      </div>
                    </div>
                  }
                  
                </div>
              </Form>
      }
            </Formik>
          </div>
        </div>

      {btnLoding && <Loading />}
      <TablePaging
            dataCollection={ordersList}
            columns={columns}
            pageSize={15}
            headerTitle="لیست سفارشات"
            fetchData={fetchData}
      />
      <OrderModal setIsOpen={setIsOpen} isOpen={isOpen} />
    </div>
  );
};

export default OrderList;