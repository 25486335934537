/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { useAuth } from '../../../../app/@redux/selectors'
import { useDispatch } from 'react-redux'
import { authActions } from '../../../../app/@redux/slices'

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(authActions.setUserData(null))
    dispatch(authActions.setSms(false))
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
         خروج
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
