// import { ILoginResponse } from "../types/authenticate.types";
import { category, LoginReqData, RegisterReqData, SmsReqData } from "@types";
import { urls } from "../utils";
import http from "./sevices";
import config from "./config.json"
export const loginServices = (data: LoginReqData) => {
  return http
    .post(`${config.AdminApiUrl}${urls.login}`, data)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const registerServices = (data: RegisterReqData) => {
  return http
    .post(`${config.AdminApiUrl}${urls.register}`, data)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const smsServices = (data: SmsReqData) => {
  return http
    .post(`${config.AdminApiUrl}${urls.sms}`, data)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const categoryServices = () => {
  return http
    .post(`${config.AdminApiUrl}${urls.getCategory}`)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
