import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addProductReq, category, cateListForTable, cateListServices, changeMyStocks, DataNav, getStock, IPaginateParams, ITableData, modal, myStocks, productCatePaginate, storeStock } from '../../types';

interface IInitial {
  btnLoding: boolean;
  modalLoading: boolean;
  modalOpen: modal;
  cateProduct?: cateListForTable<ITableData<cateListServices>>;
  categories: category[];
  myStocks?: myStocks;
  myStocksBarcode?: string[];
  mySingleStock?: getStock;
}

const initialState: IInitial = {
  btnLoding: false,
  modalOpen: {
    isOpen: null,
    stockOrEdit: ''
  },
  modalLoading: false,
  categories: []
};

export const { actions: productActions, reducer: productReducer } = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    getProductCate: (state, { payload }: PayloadAction<productCatePaginate<IPaginateParams>>) => {},
    getCateList: (state) => {},
    getMyStocks: (state) => {},
    getMyEditStock: (state, { payload }: PayloadAction<number>) => {},

    postStoreStock: (state, { payload }: PayloadAction<storeStock>) => {},
    postAddProduct: (state, { payload }: PayloadAction<DataNav<addProductReq>>) => {},
    
    changeMyStock: (state, { payload }: PayloadAction<changeMyStocks>) => {},

    importExelFile: (state, { payload }: PayloadAction<File>) => {},

    setMySingleStock: (state, { payload }: PayloadAction<getStock>) => {
      state.mySingleStock = payload;
    },
    setCategory: (state, { payload }: PayloadAction<category[]>) => {
      state.categories = payload;
    },
    setMyStocksBarcode: (state, { payload }: PayloadAction<string[]>) => {
      state.myStocksBarcode = payload;
    },
    setMyStocks: (state, { payload }: PayloadAction<myStocks>) => {
      state.myStocks = payload;
    },
    setCateProducts: (state, { payload }: PayloadAction<cateListForTable<ITableData<cateListServices>>>) => {
        state.cateProduct = payload;
    },
    setBtnLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.btnLoding = payload;
    },
    setModalOpen: (state, { payload }: PayloadAction<modal>) => {
      state.modalOpen = payload;
    },
    setModalLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.modalLoading = payload;
    }
  }
});
