import { IPaginateParams, ordersListPaginate } from "../types";
import { urls } from "../utils";
import http from "./sevices";
import config from "./config.json"

export const getOrdersListServices = (payload: ordersListPaginate<IPaginateParams>) => {
  if(payload.paginate.link){
    return http
    .get(
      `${payload.paginate.link}&per_page=${payload.paginate.pageSize}`
     ).then((response) => response.data)
     .catch((err) => err.response.data);
    
  }else{
    return http
    .get(`${config.AdminApiUrl}${urls.orderList}`)
    .then((response) => response.data)
    .catch((err) => err.response.data);
    
  }
};

export const orderReportServices = () => {
  return http
    .post(`${config.AdminApiUrl}${urls.orderReport}`)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};

export const searchOrdersTimeServices = (filter: {start: string, end: string }) => {
  return http
    .post(`${config.AdminApiUrl}${urls.searchOrderTime}` , filter)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const searchOrdersMobileServices = (filter: {key: string}) => {
  return http
    .post(`${config.AdminApiUrl}${urls.searchOrderMobile}` , filter)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const searchOrdersCodeServices = (filter: {key: number}) => {
  return http
    .post(`${config.AdminApiUrl}${urls.searchOrderCode}` , filter)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};
export const searchOrdersStatusServices = (filter: {shop_status: number}) => {
  return http
    .post(`${config.AdminApiUrl}${urls.searchOrderStatus}` , filter)
    .then((response) => response.data)
    .catch((err) => err.response.data);
};

