// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading_container__2ppkH {\n  position: fixed;\n  text-align: center;\n  border-radius: 0.475rem;\n  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);\n  background-color: #fff;\n  color: #7e8299;\n  font-weight: 500;\n  margin: 0;\n  width: auto;\n  padding: 1rem 2rem;\n  top: 50%;\n  left: 50%;\n  transform: translateX(-50%) translateY(-50%);\n  z-index: 1;\n}", "",{"version":3,"sources":["webpack://./src/app/components/general/Loading/loading.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,uBAAA;EACA,8CAAA;EACA,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,4CAAA;EACA,UAAA;AACF","sourcesContent":[".container {\n  position: fixed;\n  text-align: center;\n  border-radius: 0.475rem;\n  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);\n  background-color: #fff;\n  color: #7e8299;\n  font-weight: 500;\n  margin: 0;\n  width: auto;\n  padding: 1rem 2rem;\n  top: 50%;\n  left: 50%;\n  transform: translateX(-50%) translateY(-50%);\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "loading_container__2ppkH"
};
export default ___CSS_LOADER_EXPORT___;
