import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import OrderList from './orderList'
import OrderReport from './orderReport'

type Props = {}
const OrdersBreadCrumbs: Array<PageLink> = [
  {
    title: 'سفارشات من',
    path: '/order',
    isSeparator: false,
    isActive: false,
  }
]
const Orders = (props: Props) => {
  return (
    <Routes>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={OrdersBreadCrumbs}>لیست سفارشات</PageTitle>
              <OrderList />
            </>
          }
        />
        <Route
          path='report'
          element={
            <>
              <PageTitle breadcrumbs={OrdersBreadCrumbs}>استعلام</PageTitle>
              <OrderReport />
            </>
          }
        />

      <Route index element={<Navigate to='/order/list' />} />
    </Routes>
  )
}

export default Orders;
