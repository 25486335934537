import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import RootSaga from './rootSaga';

import { authReducer, globalsReducer , productReducer, settingsReducer ,orderReducer} from './slices';
// import { purchasePlanReducer } from './slices/purchasePlan.slice';
// import { discountReducer } from './slices/discount.slice';

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  auth: authReducer,
  globals: globalsReducer,
  product: productReducer,
  settings: settingsReducer,
  order: orderReducer
});

const persistConfig = {
  key: 'root',
  whitelist: ['auth', 'globals'],
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: false,
      serializableCheck: false
    }).prepend(sagaMiddleware);
  }
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

sagaMiddleware.run(RootSaga);
